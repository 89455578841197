<template>
  <div class="page">
    <section>
      <span class="title">支付</span>
      <div class="box">
        <div class="left">
          <div class="leftBox">
            <span class="leftTitle">请选择转账方式</span>
            <div class="pay" v-for="(item, index) in list" :key="index">
              <img :src="item.img" alt="" />
              <span>{{ item.name }}</span>
              <span class="prompt">{{ item.prompt }}</span>
              <i
                @click="turnOff = !turnOff"
                class="iconfont"
                :class="{
                  'zhanghu-xuanzhong': turnOff,
                  'zhanghu-weixuanzhong': !turnOff,
                }"
              ></i>
            </div>
          </div>
          <span
            @click="$router.push({ path: '/research-questionnaire-index' })"
            class="determine"
            >确定</span
          >
        </div>
        <messageTwo></messageTwo>
      </div>
    </section>
  </div>
</template>

<script>
import messageTwo from "@components/message/message";
export default {
  components: {
    messageTwo,
  },
  data() {
    return {
      turnOff: false,
      list: [
        {
          img: require("@assets/image/yinhangka.png"),
          name: "向银行卡转账",
          prompt: "输入银行卡号，可向你的银行卡转账",
        },
        {
          img: require("@assets/image/weixin.png"),
          name: "向微信转账",
          prompt: "绑定微信，可想你的微信转账",
        },
        {
          img: require("@assets/image/zhifubao.png"),
          name: "向支付宝转账",
          prompt: "输入支付宝号，可向你的支付宝转账",
        },
      ],
    };
  },
};
</script>

<style lang='scss' scoped>
@import "@assets/css/themeColor.scss";

.zhanghu-xuanzhong {
  color: $--themeColor;
}
.zhanghu-weixuanzhong {
  color: #8f8f8f;
}
.page {
  > section {
    display: flex;
    flex-direction: column;
    > .title {
      font-size: 18px;
      color: #333333;
      margin: 30px 0 22px 0;
    }
    > .box {
      display: flex;
      flex-direction: row;
      > .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 16px;
        > .leftBox {
          background: #f2f2f2;
          width: 890px;
          padding: 32px 76px 53px 74px;
          box-sizing: border-box;
          font-size: 16px;
          color: #333333;
          > .pay {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 740px;
            height: 76px;
            background: #ffffff;
            border-radius: 10px;
            padding: 16px 20px;
            box-sizing: border-box;
            margin-top: 20px;
            > img {
              width: 44px;
              height: 44px;
              object-fit: cover;
              margin-right: 10px;
            }
            > .prompt {
              font-size: 12px;
              color: #8f8f8f;
              margin-left: 10px;
            }
          }
          .iconfont {
            margin-left: auto;
          }
        }
        > .determine {
          cursor: pointer;
          width: 384px;
          height: 46px;
          font-size: 15px;
          color: #ffffff;
          background: $--themeColor;
          border-radius: 23px;
          text-align: center;
          line-height: 46px;
          margin-top: 50px;
        }
      }
    }
  }
}
</style>